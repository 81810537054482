import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import enHome from './locales/en/home.json';

import zhTranslation from './locales/zh/translation.json';
import zhHome from './locales/zh/home.json';

// pls look into the doc: https://react.i18next.com/guides/quick-start
// another doc, which is instruction for custom component: https://react.i18next.com/guides/quick-start#using-the-hoc

const enCombination = Object.assign({}, enHome, enTranslation);
const zhCombination = Object.assign({}, zhHome, zhTranslation);
// the translations
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: enCombination,
  },
  zh: {
    translation: zhCombination,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
